var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('dashboard-chart', {
    attrs: {
      "title": "Reps",
      "total": _vm.downlineStats.total,
      "chartOptions": _vm.downlineChartOptions,
      "chartSeries": _vm.downlineSeries
    },
    scopedSlots: _vm._u([{
      key: "listInfo",
      fn: function fn() {
        return [_c('ul', [_c('li', [_c('strong', [_vm._v("States:")]), _vm._v(" " + _vm._s(_vm.downlineStats.state_count))]), _c('li', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": _vm.downlineStats.no_rep_state
          }
        }, [_c('strong', [_vm._v("States with no rep:")]), _vm._v(" " + _vm._s(_vm.countNoState(_vm.downlineStats.no_rep_state)) + " ")])])];
      },
      proxy: true
    }])
  }), _c('dashboard-chart', {
    staticClass: "emp-group-dashboard",
    attrs: {
      "title": "Emp & Group",
      "total": _vm.groupStats.total,
      "chartOptions": _vm.groupChartOptions,
      "chartSeries": _vm.groupSeries
    }
  }), _c('dashboard-chart', {
    staticClass: "dashboard-graph-sizer",
    attrs: {
      "title": "Members",
      "total": (_vm.memberStats.downline_total ? _vm.memberStats.downline_total : 0) + (_vm.memberStats.direct_total ? _vm.memberStats.direct_total : 0),
      "chartOptions": _vm.memberChartOptions,
      "chartSeries": _vm.memberSeries
    },
    scopedSlots: _vm._u([{
      key: "listInfo",
      fn: function fn() {
        return [_c('ul', [_c('li', [_c('strong', [_vm._v("States:")]), _vm._v(" " + _vm._s(_vm.memberStats.state_count))]), _c('li', [_c('strong', [_vm._v("Avg Age:")]), _vm._v(_vm._s(_vm.memberStats.average_age))]), _c('li', [_c('strong', [_vm._v("M:")]), _vm._v(" " + _vm._s(_vm._f("percent")(_vm.memberStats.male_percent)) + " | "), _c('strong', [_vm._v("F:")]), _vm._v(" " + _vm._s(_vm._f("percent")(_vm.memberStats.female_percent)) + " ")])])];
      },
      proxy: true
    }])
  }), _c('dashboard-chart', {
    staticClass: "dashboard-graph-sizer",
    attrs: {
      "title": "Plans",
      "total": (_vm.policyStats.downline_total ? _vm.policyStats.downline_total : 0) + (_vm.policyStats.direct_total ? _vm.policyStats.direct_total : 0),
      "chartOptions": _vm.policyChartOptions,
      "chartSeries": _vm.policySeries
    },
    scopedSlots: _vm._u([{
      key: "listInfo",
      fn: function fn() {
        return [_c('ul', [_c('li', [_c('strong', [_vm._v("Medical:")]), _vm._v(" " + _vm._s((_vm.policyStats.medical_plan.direct_total ? _vm.policyStats.medical_plan.direct_total : 0) + (_vm.policyStats.medical_plan.downline_total ? _vm.policyStats.medical_plan.downline_total : 0)) + " ")]), _c('li', [_c('strong', [_vm._v("SA:")]), _vm._v(" " + _vm._s((_vm.policyStats.sa_plan.direct_total ? _vm.policyStats.sa_plan.direct_total : 0) + (_vm.policyStats.sa_plan.downline_total ? _vm.policyStats.sa_plan.downline_total : 0)) + " ")]), _c('li', [_c('strong', [_vm._v("Riders:")]), _vm._v(" " + _vm._s((_vm.policyStats.rider_plan.direct_total ? _vm.policyStats.rider_plan.direct_total : 0) + (_vm.policyStats.rider_plan.downline_total ? _vm.policyStats.rider_plan.downline_total : 0)) + " ")])])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashoverviews-card-title"
  }, [_c('h2', [_vm._v("Overview")])]);
}]

export { render, staticRenderFns }