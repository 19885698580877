var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-md-6 col-sm-6 col-12 mb-3"
  }, [_c('div', {
    staticClass: "overallInfo-blockWrapper"
  }, [_c('div', {
    staticClass: "overallInfo-block"
  }, [_c('div', {
    staticClass: "overallInfo-content "
  }, [_c('h3', [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "valueDenote"
  }, [_c('ul', _vm._l(_vm.chartOptions.labels, function (l, i) {
    return _c('li', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      key: i,
      attrs: {
        "title": l + ' ' + _vm.chartSeries[i]
      }
    }, [_c('span', {
      style: {
        backgroundColor: _vm.chartOptions.colors[i]
      }
    }), _vm._v(" " + _vm._s(l) + " (" + _vm._s(_vm.chartSeries[i]) + ") ")]);
  }), 0)])]), _c('apexchart', {
    staticClass: "info-donut",
    attrs: {
      "height": "90%",
      "type": "donut",
      "options": _vm.chartOptions,
      "series": _vm.chartSeries
    }
  })], 1), _c('div', {
    staticClass: "small-screenDisplay"
  }, [_vm._t("listInfo")], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }