<template>
  <div class="row">
    <div class="dashoverviews-card-title">
      <h2>Overview</h2>
    </div>
    <dashboard-chart
      title="Reps"
      :total="downlineStats.total"
      :chartOptions="downlineChartOptions"
      :chartSeries="downlineSeries"
    >
      <template v-slot:listInfo>
        <ul>
          <li><strong>States:</strong> {{ downlineStats.state_count }}</li>
          <li v-b-tooltip.hover :title="downlineStats.no_rep_state">
            <strong>States with no rep:</strong>
            {{ countNoState(downlineStats.no_rep_state) }}
          </li>
        </ul>
      </template>
    </dashboard-chart>

    <dashboard-chart
    class="emp-group-dashboard"
      title="Emp & Group"
      :total="groupStats.total"
      :chartOptions="groupChartOptions"
      :chartSeries="groupSeries"
    />

    <dashboard-chart
      title="Members"
      class="dashboard-graph-sizer"
      :total="
        (memberStats.downline_total ? memberStats.downline_total : 0) +
        (memberStats.direct_total ? memberStats.direct_total : 0)
      "
      :chartOptions="memberChartOptions"
      :chartSeries="memberSeries"
    >
      <template v-slot:listInfo>
        <ul>
          <li><strong>States:</strong> {{ memberStats.state_count }}</li>
          <!-- <li v-b-tooltip.hover :title="memberStats.no_state_list">
            <strong>States with no mem:</strong>
            {{ countNoState(memberStats.no_state_list) }}
          </li> -->
          <li><strong>Avg Age:</strong>{{ memberStats.average_age }}</li>
          <li>
            <strong>M:</strong> {{ memberStats.male_percent | percent }} |
            <strong>F:</strong> {{ memberStats.female_percent | percent }}
          </li>
        </ul>
      </template>
    </dashboard-chart>

    <dashboard-chart
      title="Plans"
      class="dashboard-graph-sizer"
      :total="
        (policyStats.downline_total ? policyStats.downline_total : 0) +
        (policyStats.direct_total ? policyStats.direct_total : 0)
      "
      :chartOptions="policyChartOptions"
      :chartSeries="policySeries"
    >
      <template v-slot:listInfo>
        <ul>
          <li>
            <strong>Medical:</strong>
            {{
              (policyStats.medical_plan.direct_total
                ? policyStats.medical_plan.direct_total
                : 0) +
              (policyStats.medical_plan.downline_total
                ? policyStats.medical_plan.downline_total
                : 0)
            }}
          </li>
          <li>
            <strong>SA:</strong>
            {{
              (policyStats.sa_plan.direct_total
                ? policyStats.sa_plan.direct_total
                : 0) +
              (policyStats.sa_plan.downline_total
                ? policyStats.sa_plan.downline_total
                : 0)
            }}
          </li>
          <li>
            <strong>Riders:</strong>
            {{
              (policyStats.rider_plan.direct_total
                ? policyStats.rider_plan.direct_total
                : 0) +
              (policyStats.rider_plan.downline_total
                ? policyStats.rider_plan.downline_total
                : 0)
            }}
          </li>
        </ul>
      </template>
    </dashboard-chart>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import DashboardChart from "@/components/ui/DashboardChart.vue";
export default {
  name: "OverallInfo",
  components: { DashboardChart },
  data: () => ({
    downlineStats: [],
    downlineSeries: [],
    downlineChartOptions: {},
    groupStats: [],
    groupSeries: [],
    groupChartOptions: {},
    memberStats: [],
    memberSeries: [],
    memberChartOptions: {},
    policyStats: [],
    policySeries: [],
    policyChartOptions: {},
  }),
  methods: {
    getPolicyStats() {
      const url = `v2/get-policies`;
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          this.policyStats = data;
          let [active, withdrawn, termed] = [
            (data.direct_active ?? 0) + (data.downline_active ?? 0),
            (data.direct_withdrawn ?? 0) + (data.downline_withdrawn ?? 0),
            (data.direct_termed ?? 0) + (data.downline_termed ?? 0),
          ];
          let total = data.downline_total + data.direct_total;
          if (data) {
            this.policySeries = [active, withdrawn, termed];
            const [labels, colors] = [
              ["Active", "Withdrawn", "Termed", "&nbsp;"],
              ["#02BC77", "#F29423", "#EA584E", "#fff"],
            ];
            this.policyChartOptions = this.chartOptions(labels, colors, total);
          }
        })
        .catch(() => {
          this.policyStats = [];
        });
    },
    getMemberStats() {
      const url = `v2/get-agent-member`;
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          this.memberStats = data;
          let [active, withdrawn, termed] = [
            (data.direct_active ?? 0) + (data.downline_active ?? 0),
            (data.direct_withdrawn ?? 0) + (data.downline_withdrawn ?? 0),
            (data.direct_termed ?? 0) + (data.downline_termed ?? 0),
          ];
          let total = data.downline_total + data.direct_total;
          if (data) {
            this.memberSeries = [active, withdrawn, termed];
            const [labels, colors] = [
              ["Active", "Withdrawn", "Termed", "&nbsp;"],
              ["#02BC77", "#F29423", "#EA584E", "#fff"],
            ];
            this.memberChartOptions = this.chartOptions(labels, colors, total);
          }
        })
        .catch(() => {
          this.memberStats = [];
        });
    },
    getGroupStats() {
      const url = `v2/group-stats`;
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          this.groupStats = data;
          if (data) {
            this.groupSeries = [
              data.direct_active ?? 0,
              data.direct_inactive ?? 0,
              data.downline_active ?? 0,
              data.downline_inactive ?? 0,
            ];
            const [labels, colors] = [
              [
                "Direct Active",
                "Direct Inactive",
                "Downline Active",
                "Downline Inactive",
              ],
              ["#02BC77", "#F29423", "#008ffb", "#EA584E"],
            ];
            this.groupChartOptions = this.chartOptions(
              labels,
              colors,
              this.groupStats.total
            );
          }
        })
        .catch(() => {
          this.groupStats = [];
        });
    },
    getRepStats() {
      const url = `v2/downline-stats`;
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          this.downlineStats = data;
          if (data) {
            this.downlineSeries = [
              data.downline_active ?? 0,
              data.downline_disabled ?? 0,
              data.downline_suspended ?? 0,
              data.downline_pending ?? 0,
            ];
            const [labels, colors] = [
              ["Active", "Disabled", "Suspend", "Pending"],
              ["#02BC77", "#F29423", "#EA584E", "#008ffb"],
            ];
            this.downlineChartOptions = this.chartOptions(
              labels,
              colors,
              this.downlineStats.total
            );
          }
        })
        .catch(() => {
          this.downlineStats = [];
        });
    },
    countNoState(val) {
      if (val) {
        let states = val.split(",");
        return states.length;
      }
    },
    chartOptions(labels, colors, total) {
      const options = {
        labels: labels,
        plotOptions: {
          pie: {
            customScale: 1,
            expandOnClick: true,
            donut: {
              size: "45%",
            },
          },
          responsive: [
            {
            breakpoint: 1399,
              options: {
                plotOptions: {
                  chart: {
                    customScale: 1.5,
                  }
                },
                legend: {
                  position: "bottom"
                }
              },
            },
          ],
        },
        legend: {
          show: false,
        },
        dropShadow: {
          enable: false,
        },
        colors: colors,
        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: false,
          },
          style: {
            fontSize: "8px",
          },
        },
        tooltip: {
          x: {
            show: true,
          },
          y: {
            formatter: function (value) {
              let percent = (value / total) * 100;
              percent = percent.toFixed(2) + "%";
              return `${value}(${percent})`;
            },
          },
        },
      };
      return options;
    },
  },
  created() {
    this.getRepStats();
    this.getGroupStats();
    this.getMemberStats();
    this.getPolicyStats();
  },
};
</script>

<style>
.dashboard-graph-sizer .overallInfo-block ul li:last-child {
  visibility: hidden;
}
</style>