<template>
  <div class="col-xxl-3 col-xl-3 col-md-6 col-sm-6 col-12 mb-3">
    <div class="overallInfo-blockWrapper">
      <div class="overallInfo-block">
        <div class="overallInfo-content ">
          <h3>{{ title }}</h3>
          <!-- <span class="total-title"
            ><strong>Total: {{ total }}</strong></span
          > -->
          <div class="valueDenote">
            <ul>
              <li
                v-for="(l, i) in chartOptions.labels"
                :key="i"
                v-b-tooltip.hover
                :title="l +' '+ chartSeries[i]"
              >
               <span :style="{backgroundColor:chartOptions.colors[i]}"></span> {{ l }} ({{chartSeries[i]}})
              </li>
            </ul>
          </div>
        </div>
        <apexchart
          class="info-donut"
          height="90%"
          type="donut"
          :options="chartOptions"
          :series="chartSeries"
        ></apexchart>
      </div>
      <div class="small-screenDisplay">
        <slot name="listInfo"></slot>
      </div>
      <!-- <div class="detail-btn text-end">
                    <a href="#">View Detail</a>
                </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    total: {
      required: true,
      default:0
    },
    chartOptions: {
      required: true,
    },
    chartSeries: {
      required: true,
    },
  },
};
</script>

<style scoped>

</style>