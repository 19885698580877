<template>
    <div class="page-wrapper">
      <div class="container-fluid">
        <div class="col-12 mb-4 dashboard-overview-wrap">
          <overall-info />
        </div>
      </div>
    </div>
  </template>
  <script>
  import OverallInfo from "@/views/Dashboard/partials/OverallInfo.vue";
  export default {
    name: "BusinessStat",
    components: {
      OverallInfo,
    },
  };
  </script>
  <style src="@/assets/css/repdashboard.css"></style>