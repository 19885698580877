var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "col-12 mb-4 dashboard-overview-wrap"
  }, [_c('overall-info')], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }